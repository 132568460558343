<template>
  <div class="list-view product-checkout">
    <!-- Left Card -->
    <b-card no-body>
      <b-card-header class="flex-column align-items-start">
        <b-card-title>خيارات الدفع</b-card-title>
        <!-- <b-card-text class="text-muted mt-25">
          Be sure to click on correct payment option
        </b-card-text> -->
      </b-card-header>
      <b-card-body>
        <!-- <h6 class="card-holder-name my-75">
          John Doe
        </h6> -->

        <!-- Radios -->
        <b-form-group>
          <!--
          <b-form-radio
            name="payment-method"
            value="john-doe-debit-card"
            checked="john-doe-debit-card"
          >
            US Unlocked Debit Card 12XX XXXX XXXX 0000
          </b-form-radio>
          <div class="d-flex flex-wrap align-items-center justify-content-start my-1">
            <label
              for="cvv"
              class="text-nowrap mr-1 mb-1"
            >
              Enter CVV:
            </label>
            <b-form-input
              id="cvv"
              v-model="paymentDetails.cvv"
              class="mr-1 mb-1"
              trim
            />
            <b-button
              variant="primary"
              class="mb-1"
            >
              Continue
            </b-button>
          </div>

          <hr class="mb-2 mt-1"> -->

          <!-- <b-form-radio name="payment-method" v-model="paymentDetails.payment_method" value="creditcard">
            بطاقة الائتمان
          </b-form-radio> -->
          <!-- <b-form-radio
            name="payment-method"
            class="mt-1"
            value="net-banking"
          >
            Net Banking
          </b-form-radio> -->
          <b-form-radio
            name="payment-method"
            class="mt-1"
            v-model="paymentDetails.payment_method_uuid"
            value="wallet"
            :disabled="balance < cart.grand_total"
          >
            المحفظة

            {{ __formatMoney(balance) }}
          </b-form-radio>
          <b-form-radio name="payment-method" v-model="paymentDetails.payment_method_uuid" class="mt-1" value="sales">
            رصيد المبيعات
          </b-form-radio>
        </b-form-group>

        <hr class="my-2" />
        <b-button
          variant="primary"
          style="float: left"
          :disabled="submitProcessing || !paymentDetails.payment_method_uuid"
          @click="checkOut"
        >
          دفع
        </b-button>
        <!-- <p class="cursor-pointer">
          <feather-icon icon="PlusCircleIcon" size="21" class="mr-75" />
          <span class="align-middle">Add Gift Card</span>
        </p> -->
      </b-card-body>
    </b-card>

    <!-- Right Card -->
    <div class="amount-payable checkout-options">
      <b-card title="تفاصيل الدفع">
        <ul class="list-unstyled price-details" v-if="cart">
          <li class="price-detail">
            <div class="details-title">اجمالي المنتجات</div>
            <div class="detail-amt">
              <strong> {{ __formatMoney(cart.total) }}</strong>
            </div>
          </li>
          <!-- <li class="price-detail">
            <div class="details-title">Delivery Charges</div>
            <div class="detail-amt discount-amt text-success">Free</div>
          </li> -->
        </ul>
        <hr />
        <ul class="list-unstyled price-details">
          <li class="price-detail">
            <div class="details-title">المبلغ المستحق</div>
            <div class="detail-amt font-weight-bolder">
              {{ __formatMoney(cart.grand_total) }}
            </div>
          </li>
        </ul>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BFormGroup,
  BFormRadio,
  BFormInput,
  BButton
} from "bootstrap-vue";
import store from "@/store";
import { postForm } from "@useApi";
import { ref, toRefs, watchEffect, computed } from "@vue/composition-api";
import { $responseSolve, $makeToast, $errorsres } from "@/libs/soveAlert.js";
import router from "@/router";
export default {
  components: {
    // BSV
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BButton
  },
  props: {
    paymentDetails: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    //   const cart = ref(store.state.cataloge.cart);
    const refFormWizard = ref("");
    const submitProcessing = ref(false);
    const cart = computed(() => store.state.cataloge.cart);
    const user = computed(() => store.state.auth?.user);
    const balance = computed(() => store.state.auth.balance);
    const checkOut = () => {
      var formData = {
        ...props.paymentDetails,
        ...cart.value
      };
      submitProcessing.value = true;
      postForm(`checkout/${formData.id}`, formData)
        .then(res => {
          let msg = $responseSolve(res, true);
          $makeToast("success", msg, "success");
          store.dispatch("cataloge/fetchCartProducts");
          router.push({ name: "admin.order.index" });
        })
        .catch(error => {
          //  console.log('error',error)
          submitProcessing.value = false;

          // this.$router.push({ name: 'misc-error-404' })

          let msg = $responseSolve(error, true, "danger");
          //  self.errorsres = this.$errorsres(error);
          //  this.flash.level = "danger";
          //  this.flash.message = msg;
          $makeToast("danger", msg, "failed");

          // if(error.response && error.response.data && error.response.data.errors){
          //   self.errorsres = error.response.data.errors;
          // }
          // refFormWizard.value.setErrors($errorsres(error));
        });
    };

    return {
      submitProcessing,
      refFormWizard,
      cart,
      user,
      balance,
      checkOut
    };
  }
};
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}
</style>
