<template>
  <div>
    <b-row class="content-header">
      <!-- Content Left -->
      <b-col class="content-header-left mb-2" cols="12" md="9">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">الدفع</h2>
            <!-- <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item :to="{ name: 'front.Home' }">
                  المنتجات
                </b-breadcrumb-item>

                <b-breadcrumb-item :active="true">
                  تفاصيل المنتج
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div> -->
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <form-wizard
      ref="refFormWizard"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="Previous"
      hide-buttons
      class="checkout-form-wizard steps-transparent"
    >
      <!-- account detail tab -->
      <tab-content title="عربة التسوق" icon="feather icon-shopping-cart">
        <step-cart @next-step="formWizardNextStep" />
      </tab-content>

      <!-- address -->
      <!-- <tab-content title="Address" icon="feather icon-home">
      <step-address
        :address-details="checkoutDetails.address"
        @next-step="formWizardNextStep"
      />
    </tab-content> -->

      <!-- social link -->
      <tab-content title="الدفع" icon="feather icon-credit-card">
        <step-payment :payment-details="checkoutDetails" />
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ref } from "@vue/composition-api";
import StepPayment from "./StepPayment.vue";
import StepCart from "./StepCart.vue";
import StepAddress from "./StepAddress.vue";

export default {
  components: {
    // 3rd Party
    FormWizard,
    TabContent,

    // SFC
    StepCart,
    StepAddress,
    StepPayment
  },
  setup() {
    const refFormWizard = ref(null);

    // ? This is just dummy details
    const checkoutDetails = ref({
      //  cart: "",
      payment_method_uuid: ""
      // payment: {
      //   mehtod_uuid: "",
      // },
    });
    const formWizardNextStep = cart => {
      // checkoutDetails.value.cart = cart;
      refFormWizard.value.nextTab();
    };
    return {
      refFormWizard,
      formWizardNextStep,

      // Dummy Details
      checkoutDetails
    };
  }
};
</script>

<style lang="scss">
@import "~@resources/scss/vue/libs/vue-wizard.scss";
@import "~@resources/scss/base/pages/app-ecommerce.scss";
@import "~vue-form-wizard/dist/vue-form-wizard.min.css";
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
