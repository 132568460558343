import axios from '@axios';
var headerRequestFile = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const fetchItem = (endpoint, id = '', params = {}) => {

  let url = `${endpoint}`;
  if (id !== '') {
    url += `/${id}`;
  }

  return new Promise((resolve, reject) => {
    axios.get(url, { params: params })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};
export const fetchItems = (endpoint, params = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(endpoint, { params: params });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};


export const postForm = (endpoint, formData, uploadFile = false) => {
  var headers = {};

  if (uploadFile == true) {
    headers = headerRequestFile;
  }

  return new Promise(async (resolve, reject) => {

    try {
      const response = await axios.post(endpoint, formData, headers);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};


export const putForm = (endpoint, id = undefined, formData, uploadFile = false) => {

  var url = endpoint;
  if (id !== undefined) {
    url = `${endpoint}/${id}`;
  }
  var headers = {};

  if (uploadFile == true) {
    headers = headerRequestFile;
  }
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.put(url, formData, headers);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteItem = (endpoint, id, params = {}) => {
  //  const { t } = useI18nUtil()
  //const { t } = useI18n(); 

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.delete(`${endpoint}/${id}`, { params: params });

      resolve(response.data);
    } catch (error) {

      reject(error);
    }
  });






};

export const fetchDataApi = (configObject) => {
  const { url, params, failer, callback } = configObject;

  fetchItems(url, params).then((data) => {
    if (data.data) {
      configObject.callback(data.data);
    } else if (data.result) {
      configObject.callback(data.result);
    } else {
      configObject.callback(data);
    }


  });


}

export const ifetch = async (endpoint, params = {}, id = undefined) => {


  var url = endpoint;
  if (id !== undefined) {
    url = `${endpoint}/${id}`;
  }
  const { data, error } = await $api(url, params, 'get');

  if (error) {
    //  console.error(error);
    return null;
  }
  return data;

}


export const $api = async (url, params = {}, method = 'get') => {

  let response;
  try {
    // const response = ref([]);


    switch (method) {
      case 'get':
        response = await axios.get(url, { params: params });
        //   const response = await axios.get(endpoint);
        // data.value = await response.json();
        return { data: response.data, error: null };
      case 'post':
        response = await axios.post(url, params);
        return { data: response.data, error: null };
      case 'put':
        response = await axios.put(url, params);
        return { data: response.data, error: null };
      case 'delete':
        response = await axios.delete(url, params);
        return { data: response.data, error: null };
      default:
        response = await axios.get(url, { params: params });
        return { data: response.data, error: null };
    }



    // return response.value.data;
  } catch (error) {
    // console.error("Error:", error); 
    return { data: null, error: error };

  }

};

//const { data: fetchedData, error: fetchError } =

// const postData = async (formData) => {
//   const data = ref(null);
//   const error = ref(null);

//   try {

//     const response = await axios.post(endpoint, formData);
//     data.value = await response.json();
//   } catch (error) {
//     console.error(error);
//     error.value = error;
//   }

//   return { data, error };
// };


// const handleFormSubmit = async (formData) => {
//   const { data: postDataResponse, error: postError } = await postData(formData);
// if (postError) {
//   console.error(postError);
//   return;
// }

//   console.log('Post data response:', postDataResponse);
//   // Perform additional actions or update the UI based on the response
// };