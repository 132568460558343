<template>
  <div class="list-view product-checkout mt-0">
    <template v-for="(item, index) in products">
      <step-cart-products :items="item.items" :key="item.id" @updateProduct="newVal => updateItems(newVal, index)" />

      <!-- Checkout Options -->
      <div class="checkout-options">
        <b-card>
          <h4>{{ item.shop.name }}</h4>
          <!-- <label class="section-label mb-1">Options</label> -->
          <!-- <b-input-group class="input-group-merge coupons">
          <b-form-input placeholder="Coupons" />
          <b-input-group-append is-text>
            <span
              id="input-coupons"
              class="input-group-text text-primary cursor-pointer"
              >Apply</span
            >
          </b-input-group-append>
        </b-input-group> -->
          <hr />
          <div class="price-details">
            <!-- <h6 class="price-title">Price Details</h6>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title">Total MRP</div>
              <div class="detail-amt">$598</div>
            </li>
            <li class="price-detail">
              <div class="detail-title">Bag Discount</div>
              <div class="detail-amt discount-amt text-success">-25$</div>
            </li>
            <li class="price-detail">
              <div class="detail-title">Estimated Tax</div>
              <div class="detail-amt">$1.3</div>
            </li>
            <li class="price-detail">
              <div class="detail-title">EMI Eligibility</div>
              <a href="javascript:void(0)" class="detail-amt text-primary"
                >Details</a
              >
            </li> -->
            <!-- <li class="price-detail">
              <div class="detail-title">Delivery Charges</div>
              <div class="detail-amt discount-amt text-success">Free</div>
            </li></ul> -->

            <ul class="list-unstyled">
              <li class="price-detail">
                <div class="detail-title detail-total">المجموع</div>
                <div class="detail-amt font-weight-bolder">
                  {{ __formatMoney(item.grand_total) }}
                </div>
              </li>
            </ul>
            <b-button variant="primary" block @click="nextStep(item)"> التالي </b-button>
          </div>
        </b-card>
      </div>
    </template>
  </div>
</template>

<script>
import { BButton, BCard, BInputGroup, BFormInput, BInputGroupAppend } from "bootstrap-vue";
import store from "@/store";
import { ref, toRefs } from "@vue/composition-api";
import StepCartProducts from "./StepCartProducts.vue";
import { useCataloge, useEcommerceUi } from "../useCataloge";
export default {
  components: {
    // BSV
    BButton,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,

    // SFC
    StepCartProducts
  },
  setup(props, { emit }) {
    const products = ref([]);
    const updateItems = (items, index) => {
      if (items.length == 0) {
        products.value = products.value.filter((_, i) => i !== index);
      } else {
        products.value.map((n, i) => {
          if (index == i) {
            // items.map((item) => {
            //   n.total += item.total;
            //   n.grand_total += item.total;
            // });
            //  parseInt
            n.items = items;
            n.grand_total = items.reduce((acc, item) => acc + item.total, 0);
            n.total = items.reduce((acc, item) => acc + item.total, 0);
            // return {
            //   ...item,
            // };
          }
          return n;
        });
      }

      store.commit(
        "cataloge/UPDATE_CART_ITEMS_COUNT",
        products.value.reduce((acc, item) => acc + item.items.length, 0)
      );
      store.commit("cataloge/setCarts", products.value);
    };

    const nextStep = cart => {
      store.commit("cataloge/setCart", cart);

      emit("next-step", cart);
    };

    const fetchCartProducts = () => {
      store.dispatch("cataloge/fetchCartProducts").then(response => {
        products.value = response.data.products;
      });
    };
    fetchCartProducts();

    return {
      products,
      updateItems,
      nextStep
    };
  }
};
</script>
