<template>
  <div class="checkout-items">
    <b-card
      v-for="(product, index) in products"
      :key="product.id"
      class="ecommerce-card"
      no-body
    >
      <!-- Product Image -->
      <div class="item-img">
        <b-link>
          <b-img :src="product.image" :alt="`${product.name}-${product.id}`" />
        </b-link>
      </div>

      <!-- Product Details: Card Body -->
      <b-card-body>
        <div class="item-name">
          <h6 class="mb-0">
            <b-link class="text-body">
              {{ product.name }}
            </b-link>
          </h6>
          <span class="item-company"
            >البراند
            <b-link class="company-name">{{ product.brand }}</b-link></span
          >
          <h4 class="item-price1 mr-1">{{ __formatMoney(product.price) }}</h4>
          <div class="item-rating">
            <ul class="unstyled-list list-inline">
              <li
                v-for="star in 5"
                :key="star"
                class="ratings-list-item"
                :class="{ 'ml-25': star - 1 }"
              >
                <feather-icon
                  icon="StarIcon"
                  size="16"
                  :class="[
                    { 'fill-current': star <= product.rating },
                    star <= product.rating ? 'text-warning' : 'text-muted',
                  ]"
                />
              </li>
            </ul>
          </div>
        </div>
        <!-- <span class="text-success mb-1">In Stock</span> -->
        <div class="item-quantity">
          <span class="quantity-title">الكمية:</span>
          <b-form-spinbutton
            v-model="product.quantity"
            size="sm"
            class="ml-75"
            inline
            @change="updateItems(product)"
          />
        </div>
        <!-- <span class="delivery-date text-muted"
          >Delivery by
          {{
            formatDate(product.shippingDate, {
              month: "short",
              day: "numeric",
              weekday: "short",
            })
          }}</span
        > -->
        <!-- <span class="text-success"
          >{{ product.discountPercentage }}% off {{ product.offers }} offers
          Available</span
        > -->
      </b-card-body>

      <!-- Product Options/Actions -->
      <div class="item-options text-center">
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">{{ __formatMoney(product.total) }}</h4>
            <!-- <p v-if="product.hasFreeShipping" class="card-text shipping">
              <b-badge pill variant="light-success"> Free Shipping </b-badge>
            </p> -->
          </div>
        </div>
        <b-button
          variant="light"
          class="mt-1 remove-wishlist"
          @click="removeProductFromCartClick(product)"
        >
          <feather-icon icon="XIcon" class="mr-50" />
          <span></span>
        </b-button>
        <b-button
          variant="primary"
          class="btn-cart move-cart"
          @click="toggleProductInWishlist(product)"
        >
          <feather-icon
            icon="HeartIcon"
            class="mr-50"
            :class="{ 'fill-current': product.isInWishlist }"
          />
          <span class="text-nowrap"></span>
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BLink,
  BImg,
  BButton,
  BBadge,
  BFormSpinbutton,
} from "bootstrap-vue";
import store from "@/store";
import { ref, toRefs } from "@vue/composition-api";
import { formatDate } from "@core/utils/filter";
import { useCataloge, useEcommerceUi } from "../useCataloge";

export default {
  props: {
    items: {
      type: Array,
    },
  },
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BBadge,
    BFormSpinbutton,
  },
  setup(props, { emit }) {
    const products = ref(props.items);
    const { toggleProductInWishlist, updateQuantityHandler } = useEcommerceUi();
    const { removeItemFromCart, updateQuantity } = useCataloge();
    const removeProductFromCartClick = (product) => {
      removeItemFromCart(product.id).then(() => {
        const productIndex = products.value.findIndex(
          (p) => p.id === product.id
        );
        products.value.splice(productIndex, 1);
        emit("updateProduct", products.value);
        store.commit("cataloge/UPDATE_CART_ITEMS_COUNT", products.value.length);
      });
    };
    const updateItems = (product) => {
      //updateQuantityHandler()
      updateQuantity({
        id: product.id,
        uuid: product.uuid,
        quantity: product.quantity,
      }).then((res) => {
        // eslint-disable-next-line no-param-reassign
        //product = { ...res.data }
        product = res.data;
        // console.log("res", res.data);
        products.value = products.value.map((n, i) => {
          if (product.id == n.id) {
            //console.log("res", product.id);
            //  n.total = qty * item.price;
            return {
              ...product,
            };
          }
          return n;
        });
        //  return product;
        emit("updateProduct", products.value);
      });
    };
    return {
      // ...toRefs(props),
      products,

      // UI
      toggleProductInWishlist,
      removeProductFromCartClick,
      updateQuantityHandler,

      // Filter
      formatDate,
      updateItems,
    };
  },
};
</script>

<style></style>
